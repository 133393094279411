import axios from 'axios';
import { GraphQLClient } from 'graphql-request';
import { useQuery } from 'react-query';

export const backendApiAddress = (() => {
    if (window.location.hostname === 'localhost') {
        return 'http://localhost:3001';
    }
    if (window.location.hostname.endsWith('.test.iterate.no')) {
        return 'https://northern-playground-backend.test.iterate.no';
    }
    return 'https://api.northernplayground.no';
})();

export const backendApi = axios.create({
    withCredentials: true,
    baseURL: backendApiAddress,
});

export const graphqlClient = new GraphQLClient(`${backendApiAddress}/graphql`, {
    credentials: 'include',
});

export function useGraphQlQuery<T, Variables = Record<string, any>>(
    query: string,
    key: string,
    variables?: Variables
) {
    return useQuery<T, Error>([key, variables], () =>
        graphqlClient.request<T, Variables>(query, variables)
    );
}

export function useSanityGraphQlQuery<T, Variables = Record<string, any>>(
    query: string,
    key: string,
    variables?: Variables
) {
    return useQuery<T, Error>([key, variables], () =>
        sanityGraphqlClient.request<T, Variables>(query, variables)
    );
}

const REACT_APP_SANITY_DATASET = process.env.REACT_APP_SANITY_DATASET;

if (!REACT_APP_SANITY_DATASET) {
    throw new Error('You need to set REACT_APP_SANITY_DATASET in env');
}

export const sanityGraphqlClient = new GraphQLClient(
    `https://u9feora4.api.sanity.io/v1/graphql/${REACT_APP_SANITY_DATASET}/beta`
);
